<template>
	<div>
	
		<div class="content content_top_margin" style="min-height: 409px;">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=" text-align:left;">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper"></div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'clean-eating-a45' }" class="button"> 
																						<em>Up
																						Next: </em> Clean Eating <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37">
																				</router-link>
																			</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  vc_custom_1636691391154"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-3">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Increase your energy</h2>
																			<h3>Beauty Sleep</h3>
																			<p>&nbsp;</p>
																			<p>&nbsp;</p>
																			<div class="heading_wrapper">
																				<p><a class="headerVideoLink popup-modal"
																						href="#" data-bs-toggle="modal"
																						data-bs-target="#exampleModal1"><img
																							src="../../assets/images/airbnb/Airbnb-Video-Play.png">Watch
																						video now</a></p>

																			</div>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>

																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-9">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_right   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper vc_box_border_grey">
																				<img class="vc_single_image-img"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Sleeping-Beauty.jpg"
																					width="700" height="360"
																					alt="Airbnb---Productivity---Sleeping-Beauty"
																					title="Airbnb---Productivity---Sleeping-Beauty">
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="50" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Beauty-Sleep-7-8-hours-.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb-Productivity---Beauty-Sleep---7---8-hours-">
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1636692447354">
																		<div class="wpb_wrapper">
																			<h4>Aim for 7 – 8 hours</h4>
																			<p>&nbsp;</p>
																			<p>Sounds crazy, but make sure you schedule
																				enough time for sleep. Instead of
																				cutting back on sleep in order to tackle
																				the rest of your daily tasks, put sleep
																				at the top of your to-do list and aim
																				for 7 to 8 hours of restful sleep.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="57" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Beauty-Sleep-Bedtime-Ritual.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity---Beauty-Sleep---Bedtime-Ritual">
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1636692566484">
																		<div class="wpb_wrapper">
																			<h4>Bedtime ritual</h4>
																			<p>&nbsp;</p>
																			<p>Too ensure that you get enough rest,
																				create a constant routine. A great
																				strategy is the 3-2-1 rule.</p>
																			<p>&nbsp;</p>
																			<p>No food 3 hours before bed. No work 2
																				hours before bed. Relax 2 hour before
																				bed.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-4 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="61" height="50"
																					src="../../assets/images/airbnb/Airbnb-Productivity-Beauty-Sleep-No-Screens.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Productivity---Beauty-Sleep---No-Screens">
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1636692570566">
																		<div class="wpb_wrapper">
																			<h4>No screens</h4>
																			<p>&nbsp;</p>
																			<p>Did you know that all screens emit blue
																				light which disrupts your production of
																				melatonin, the hormone responsible for
																				feeling sleepy?</p>
																			<p>&nbsp;</p>
																			<p>Use a night filter, blue light glasses,
																				or better yet, no screens 1 hour before
																				bed.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
							<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=" text-align:left;">
							<div class=" section_inner clearfix">
								<div class="section_inner_margin clearfix">
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>Discover more productivity tips</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=" text-align:left;">
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																	<div class="wpb_wrapper">
																		<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="537"
																						src="../../assets/images/airbnb/Airbnb-Working-From-Office-Everyday-stretches.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Working-From-Office---Everyday-stretches">
																			</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'increase-your-energy-a45' }">
																			<h4>Increase your energy</h4>
																			<p>How to manage your energy rather than
																				your time.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="364"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Pomodoro.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Pomodoro"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'the-pomodoro-technique-a45' }">
																			<h4>Pomodoro Technique</h4>
																			<p>Manage your time with the Pomodoro
																				Technique.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Sensory-Overload.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Sensory-Overload"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'sensory-overload-a45' }">
																			<h4>Sensory overload</h4>
																			<p>Check out these hacks to avoid headaches,
																				eye and ear fatigue and more.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="2560" height="905"
																						src="../../assets/images/airbnb/Replacement-for-Productivity-Hacks-Structure-Your-Day2-scaled.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Replacement for Productivity Hacks - Structure Your Day2"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'productivity-hacks-a45' }">
																			<h4>Productivity hacks</h4>
																			<p>Productivity hacks to help you crush your
																				work day.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Time-Management.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity--Time-Management"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'time-management-a45' }">
																			<h4>Time management</h4>
																			<p>Manage your time between work and
																				downtime.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="482"
																						src="../../assets/images/airbnb/Airbnb-Productivity-Enhanced-Focus.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Productivity---Enhanced-Focus"
																						>
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'enhanced-focus-a45' }">
																			<h4>Enhanced focus</h4>
																			<p>Get focused and get productive with these
																				focus enhancing tips.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Video Modal start Here -->						

						<div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
							<div class="modal-dialog modal-dialog-centered modal-lg">
								<div class="modal-content">
									<div class="modal-body">
										<div class="text-end">
											<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
												@click="hideModal">
												X
											</a>
										</div>
										<div style="padding: 56.25% 0 0 0; position: relative">
											<iframe id="closed"
												src="https://player.vimeo.com/video/212430074?h=2461d2d7dc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
												allowfullscreen frameborder="0" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;"></iframe>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- Video Modal End Here -->





					</div>
				</div>


			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'increase-your-energy-a45',
		mounted(){
			document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		},
		methods: {
			hideModal() {
				//this.$emit('hide');
				document.getElementById("closed").src += "";
			},
		}
	}
</script>